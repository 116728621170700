<template>
  <v-dialog id="dialog" v-model="dialog" persistent max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn small v-on="on" class="shiny-button">
        <v-icon small class="mr-1">mdi-plus</v-icon>
        <span v-if="first">
          ADD AI MODEL ENDPOINT
        </span>
        <span v-else>
          ADD AI MODEL
        </span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span>
          Add a new AI model endpoint
        </span>
      </v-card-title>
      <v-card-text>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step editable :complete="step > 1" step="1">
              Model name
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable :complete="step > 2" step="2">
              AI endpoint URL
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable :complete="step > 3" step="3">
              Authentication
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable :complete="step > 4" step="4">
              Verification
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="5">
              Confirm & finish
            </v-stepper-step>
          </v-stepper-header>

          <!-- Model name -->

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12" height="250px">

                <v-container fluid>
                  <v-layout wrap class="justify-start">
                    <v-flex lg12 md12 sm12>
                      <v-form ref="nameForm" v-model="nameValid" v-on:submit.prevent="nameValid ? completeStepOne() : ``">
                        <v-text-field required label="Name" hint="for example 'stable-diffusion'" :rules="nameRules"
                          v-model="model.name" />

                        <v-radio-group v-model="modelType" dense>
                          <v-radio label="Default" :value="'default'"></v-radio>
                          <v-radio label="Replicate (Stable Diffusion, ControlNet, etc.)" value="replicate"></v-radio>                          
                        </v-radio-group>
                      </v-form>

                    </v-flex>

                  </v-layout>

                  <v-layout wrap class="justify-start mt-4">
                    <v-icon color="secondary" class="mr-2 mb-4">mdi-information-outline</v-icon>
                    <p>
                      <span v-if="modelType === 'default'" class="caption">
                        Model endpoints are used to group your ML servers that serve the same or similar model.
                        <br>
                        For example Stable Diffusion v1.5. They should have the same API for requests for consistency.
                      </span>
                      <span v-if="modelType === 'replicate'" class="caption">
                        When you add a <a href="https://replicate.com/" target="_blank">Replicate</a> endpoint, Meteron will automatically wait for results and upload them
                        <br>
                        to our storage bucket. This makes your application code a lot simpler.
                      </span>
                    </p>
                  </v-layout>
                </v-container>
              </v-card>

              <v-btn color="primary" class="mr-2" :disabled="!nameValid" small @click="completeStepOne()">
                Continue
              </v-btn>

              <v-btn text small outlined class="secondary--text" @click="resetForm">
                Cancel
              </v-btn>
            </v-stepper-content>

            <!-- Server URL -->

            <v-stepper-content step="2">
              <v-card class="mb-12" height="250px">
                <v-container fluid>
                  <v-layout wrap class="justify-start">
                    <v-flex lg12 md12 sm12>
                      <v-form ref="urlForm" v-model="urlValid" v-on:submit.prevent="urlValid ? step = 3 : ``">
                        <v-text-field required label="Server URL" hint="http://my-ai-server.example.com/api/predict"
                          :rules="urlRules" v-model="model.serverUrl" />
                      </v-form>
                    </v-flex>
                  </v-layout>

                  <v-layout wrap class="justify-start justify-bottom mt-4">
                    <v-icon color="secondary" class="mr-2 mb-4">mdi-information-outline</v-icon>
                    <p>
                      <span class="caption">
                        Enter the URL of your ML server. You can add more servers later.
                        <br>
                        An example could be
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span class="font-italic primary--text" v-on="on" @click="model.serverUrl = 'https://56627-01h08hk38d54mekcsas3vk2j5c-w.cloudspaces.litng.ai/api/predict'">https://56627-01h08hk38d54mekcsas3vk2j5c-w.cloudspaces.litng.ai/api/predict</span>
                          </template>
                        <span>Click to copy</span>
                      </v-tooltip>
                      
                      </span>
                    </p>
                  </v-layout>
                </v-container>
              </v-card>

              <v-btn color="primary" class="mr-2" :disabled="!nameValid" small @click="step = 3">
                Continue
              </v-btn>

              <v-btn text small outlined class="secondary--text" @click="resetForm">
                Cancel
              </v-btn>
            </v-stepper-content>

            <!-- Authentication -->

            <v-stepper-content step="3">
              <v-card class="mb-12" height="220px">
                <v-container v-if="modelType === 'replicate'" fluid>
                  <v-layout wrap class="justify-start">
                    <v-flex lg12 md12 sm12>
                      <v-form ref="apiForm" v-on:submit.prevent="urlValid ? completeStepAuthentication() : ``">
                        <v-text-field required label="Replicate API token" v-model="auth.replicateToken" />
                      </v-form>
                    </v-flex>
                  </v-layout>

                  <v-layout wrap class="justify-start justify-bottom mt-4">
                    <v-icon color="secondary" class="mr-2 mb-4">mdi-information-outline</v-icon>
                    <p>
                      <span class="caption">
                        Navigate to <a href="https://replicate.com/account" target="_blank">https://replicate.com/account</a> to get your API token.    
                      </span>
                    </p>
                  </v-layout> 
                </v-container>
                <v-container v-else fluid>
                  <v-layout wrap class="justify-start">
                    <v-radio-group v-model="authMode" dense>
                      <v-radio label="No authentication" :value="'none'"></v-radio>
                      <v-radio label="Username & password" value="basic"></v-radio>
                      <v-radio label="Header (authorization token)" value="header"></v-radio>
                    </v-radio-group>
                  </v-layout>

                  <v-layout v-if="authMode === 'basic'">
                    <v-flex lg6 md6 sm12 class="mr-4">
                      <v-text-field v-model="auth.username" label="Username" autocomplete="username"></v-text-field>
                    </v-flex>

                    <v-flex lg6 md6 sm12>
                      <v-text-field v-model="auth.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'" label="Password"
                        @click:append="showPassword = !showPassword" autocomplete="new-password"></v-text-field>
                    </v-flex>

                  </v-layout>

                  <v-layout v-if="authMode === 'header'">
                    <v-flex lg6 md6 sm12 class="mr-4">
                      <v-text-field v-model="auth.headerKey" label="Header key" hint="for example 'Authorization'"
                        autocomplete="header-key"></v-text-field>
                    </v-flex>

                    <v-flex lg6 md6 sm12>
                      <v-text-field v-model="auth.headerValue" label="Header value"
                        autocomplete="header-value"></v-text-field>
                    </v-flex>

                  </v-layout>

                  <v-layout wrap class="justify-start justify-bottom mt-4">
                    <p>
                      <v-icon color="secondary" class="mr-1">mdi-information-outline</v-icon>
                      <span class="caption mt-1">
                        We recommend to have your servers with at least basic authentication enabled.
                      </span>
                    </p>
                  </v-layout>
                </v-container>
              </v-card>

              <v-btn color="primary" class="mr-2" small @click="completeStepAuthentication()">
                Continue
              </v-btn>

              <v-btn text small outlined class="secondary--text" @click="resetForm">
                Cancel
              </v-btn>
            </v-stepper-content>

            <!--  -->
            <v-stepper-content step="4">
              <v-card class="mb-12" height="220px">
                <v-container fluid>
                  <v-layout wrap class="justify-start">

                    <v-flex lg6 md6 sm12>
                      <v-textarea outlined name="input-7-4" label="Test payload" v-model="checkPayload"
                        height="200px"></v-textarea>
                    </v-flex>

                    <v-flex lg6 md6 sm12>
                      <div v-if="checkServerLoading" class="text-center mt-5">
                        <span class="caption mt-1 mb-4">
                          Please wait, waiting for the response...
                        </span>
                        <br>
                        <v-progress-circular indeterminate color="primary" class="mt-4"></v-progress-circular>
                      </div>
                      <div class="ml-2" v-if="checkServer">
                        <span class="caption mt-1">
                          Response from model:
                        </span>
                        <markup class="" language="shell" :code="checkServer" :copyCode="checkServer" :inline="false">
                        </markup>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>

              <v-btn color="primary" :loading="checkServerLoading" class="mr-2" small @click="checkModelServer()">
                Verify
              </v-btn>

              <v-btn v-if="!checkServer" text small outlined class="secondary--text mr-2" @click="step = 5">
                Skip verification
              </v-btn>

              <v-btn v-if="checkServer" text small outlined class="primary mr-2" @click="step = 5">
                Continue
              </v-btn>

              <v-btn text small outlined class="secondary--text" @click="resetForm">
                Cancel
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="5">
              <v-card class="mb-12">
                <v-container>
                  <v-layout wrap class="pt-2">
                    <v-flex lg12 md12 sm12>
                      <v-layout wrap class="pt-2">
                        <v-flex lg12 md12 sm12>
                          <v-text-field dense label="Name" readonly outlined disabled
                            v-model="model.name"></v-text-field>
                        </v-flex>

                        <v-flex lg12 md12 sm12>
                          <v-text-field dense label="Server URL" readonly outlined disabled
                            v-model="model.serverUrl"></v-text-field>
                        </v-flex>

                        <v-flex lg12 md12 sm12>
                          <v-text-field v-if="authMode === 'none'" dense label="Authentication" readonly outlined disabled
                            value="Disabled"></v-text-field>
                          <v-text-field v-if="authMode === 'basic'" dense label="Authentication" readonly outlined
                            disabled value="Enabled (username/password)"></v-text-field>
                          <v-text-field v-if="authMode === 'header'" dense label="Authentication" readonly outlined
                            disabled value="Enabled (header)"></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>

              <v-btn color="primary" class="mr-2" small @click="createModel()">
                Create
              </v-btn>

              <v-btn text small outlined class="secondary--text" @click="resetForm">
                Cancel
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>
    
<script>
import Markup from '../helpers/Markup';

export default {
  components: {
    Markup,
  },

  data() {
    let modelName = ''
    if (this.first) {
      modelName = 'stable-diffusion'
    }
    return {
      step: 1,
      dialog: false,
      modelType: 'default',
      model: {
        name: modelName,
        serverUrl: ''
      },
      nameValid: false,
      nameRules: [
        value => !!value || 'Required.',
        value => (value || '').length <= 20 || 'Max 20 characters',
        value => (value || '').length >= 3 || 'Min 3 characters',
        value => {
          const pattern = /^[a-zA-Z0-9-_]+$/
          return pattern.test(value) || `Invalid name, only letters, numbers and '-' and '_' are allowed.`
        },
      ],

      urlValid: false,
      urlRules: [
        value => !!value || 'Required.',
        value => (value || '').length <= 100 || 'Max 100 characters',
        // value => (value || '').length >= 3 || 'Min 3 characters', 'http://'
        value => {
          // Pattern from https://stackoverflow.com/a/3809435/1098564 

          // const pattern = /^(http|https):\/\/[a-zA-Z0-9-_.]+(\/[a-zA-Z0-9-_.]+)*$/
          return this.isURL(value) || `Invalid URL.`
        },
      ],

      authMode: 'none',
      showPassword: false,
      auth: {
        username: '',
        password: '',
        headerKey: '',
        headerValue: '',
        replicateToken: '',
      },

      checkPayload: `{
  "prompt": "astronaut riding a horse"
}`
    }
  },

  props: {
    first: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    loading() {
      return this.$store.state.model.loading
    },
    error() {
      return this.$store.state.model.error
    },
    project() {
      return this.$store.state.user.user?.projects[0]
    },
    checkServer() {
      return this.$store.state.model.checkServer
    },
    checkServerLoading() {
      return this.$store.state.model.checkServerLoading
    },
  },

  methods: {
    completeStepOne() {
      if (this.modelType === 'replicate') {
        // If model is replicate, we can set the endpoint and skip the next step
        this.model.serverUrl = 'https://api.replicate.com/v1/predictions'
        this.step = 3
        return
      }
      this.step++
    },
    completeStepAuthentication() {
      if (this.modelType === 'replicate') {
        if (this.auth.replicateToken === '') {
          console.log('replicate token cannot be empty')
          return
        } 
        this.authMode = 'header'
        this.auth.headerKey = 'Authorization'
        this.auth.headerValue = `Token ${this.auth.replicateToken}`
        this.step = 5
        return
      }
      this.step++
    },
    async createModel() {
      let headers = {}
      if (this.authMode === 'header') {
        headers[this.auth.headerKey] = this.auth.headerValue
      }
      let payload = {
        name: this.model.name,
        projectId: this.project.id,
        servers: [
          {
            url: this.model.serverUrl,
            config: {
              username: this.auth.username,
              password: this.auth.password,
              headers: headers
            }
          }
        ]
      }

      await this.$store.dispatch('SetModel', payload)
      if (this.error === null) {
        console.log(this.error)
        let q = {
          projectId: this.project.id
        }
        this.$store.dispatch('ListModels', q)
        this.resetForm()
      }
    },

    async checkModelServer() {
      let headers = {}
      if (this.authMode === 'header') {
        headers[this.auth.headerKey] = this.auth.headerValue
      }

      let req = {
        projectId: this.project.id,
        payload: this.checkPayload,
        server: {
          url: this.model.serverUrl,
          config: {
            username: this.auth.username,
            password: this.auth.password,
            headers: headers
          }
        }
      }

      await this.$store.dispatch('CheckModelServer', req)
    },

    resetForm() {
      // closing dialog
      this.dialog = false
      this.step = 1

      // resetting form
      this.model.name = ''
      this.model.serverUrl = ''
    },

    isURL(str) {
        var urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
        var url = new RegExp(urlRegex, 'i');
        return str.length < 2083 && url.test(str);
    }
  }
}

</script>